<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterSubscriber">
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('eshopSubscriber.id')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.feUser" id="filter_feUser" :label="$t('eshopSubscriber.feUser')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.userEmail" id="filter_userEmail"
                         :label="$t('eshopSubscriber.email')"></app-input>
            </div>
            <div class="col-lg-1">
              <app-select
                v-model="filter.product"
                :options="products"
                :label="$t('eshopSubscriber.product')"
                track-by="id"
                id="filter_product"
                optionTitle="name"
              >
              </app-select>
            </div>
            <div class="col-lg-1">
              <app-select
                v-model="filter.firstOrder"
                :options="enabledTypeValues"
                :label="$t('eshopSubscriber.firstOrder')"
                id="filter_firstOrder"
                noEmptyValue
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtFrom"
                id="filter_createdAtFrom"
                :label="$t('eshopSubscriber.activated_at_from')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtTo"
                id="filter_createdAtTo"
                :label="$t('eshopSubscriber.activated_at_to')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('eshopSubscriber.filter.search') }}
              </button>
              <button class="btn btn-success pull-right m-t-30" @click.prevent="exportSubscribers">
                {{ $t('eshopSubscriber.filter.export') }}
              </button>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.subscriptionActiveSince"
                id="filter_subscriptionActiveSince"
                :label="$t('eshopSubscriber.activeSince')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.subscriptionActiveUntil"
                id="filter_subscriptionActiveUntil"
                :label="$t('eshopSubscriber.activeUntil')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.subscriptionCode" id="filter_subscriptionCode"
                         :label="$t('eshopSubscriber.code')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.cancelledAtFrom"
                id="filter_cancelledAtFrom"
                :label="$t('eshopSubscriber.cancelled_at_from')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.cancelledAtTo"
                id="filter_cancelledAtTo"
                :label="$t('eshopSubscriber.cancelled_at_to')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-1">
              <app-select
                v-model="filter.uniqueEmail"
                :options="enabledTypeValues"
                :label="$t('eshopSubscriber.filter.unique_email')"
                id="filter_unique_email"
                noEmptyValue
              >
              </app-select>
            </div>
            <div class="col-lg-1">
              <button class="btn btn-success pull-right m-t-30" data-test="subscriber_reset_filter"
                      @click.prevent="resetFilters">
                {{ $t('eshopSubscriber.filter.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import SubscriberFilterModel from '../../model/eshop/SubscriberFilter'
import Datepicker from '../form/Datepicker'
import DownloadFileService from '../../services/eshop/DownloadFileService'
import NotifyService from '../../services/NotifyService'
import EnabledTypeMixin from '../mixins/valueObject/eshop/EnabledTypeMixin'

export default {
  name: 'SubscriberFilter',
  mixins: [EnabledTypeMixin],
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(SubscriberFilterModel),
      advancedFilter: false
    }
  },
  computed: {
    products () {
      return this.$store.getters['eshopSubscriptionProduct/all']
    }
  },
  components: {
    appInput: Input,
    appSelect: Select,
    appDatepicker: Datepicker
  },
  methods: {
    filterSubscriber () {
      this.$store.commit('eshopSubscriber/setPage', 1)
      this.$store.commit('eshopSubscriber/setFilter', this.filter)
      this.$store.dispatch('eshopSubscriber/fetch')
    },
    resetFilters () {
      this.filter = this._.cloneDeep(SubscriberFilterModel)
      this.filterSubscriber()
    },
    exportSubscribers () {
      this.$store.dispatch('eshopSubscriber/export')
        .then((res) => {
          DownloadFileService.download(res, 'predplatitelia_', '.csv')
        })
        .catch(error => {
          console.log(error)
          NotifyService.setErrorMessage(this.$t('eshop.notify.export_failed') + ' (' + error + ')')
        })
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['eshopSubscriber/filter']
    this.$store.dispatch('eshopSubscriptionProduct/fetchAll')
  }
}
</script>

<style lang="scss">

</style>
